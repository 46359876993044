/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AddNewmodule,
  getModules,
  getSpecificModuleLessons,
} from "../../services/api";
import { TailSpin } from "react-loader-spinner";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNavTrainee from "./TopNavTrainee";
import AccessCourse from "components/AccessCourse";
import Bot from "pages/Bot";
import _ from "lodash";

const Cources = () => {
  const { courseId } = useParams();
  const { selectedCourse, storedUserId } = AccessCourse({ courseId });
  const [showAddModal, setShowAddModal] = useState(false);
  const [modules, setModules] = useState([]);
  const [coreModules, setCoreModules] = useState([]);
  const [interview, setInterview] = useState([]);
  const [newModule, setNewModule] = useState({
    module_name: "",
    module_description: "",
    // youtubeLink: "",
  });
  const [courseDetails, setCourseDetails] = useState(null)
  // const [generated, setGenerated] = useState("");
  // const generateId = () => {
  //   return uuidv4();
  // };
  const fetchModules = async (courseId) => {
    try {
      const response = await getModules(courseId);
      if (response) {
        setCourseDetails(response)
        const modules = response?.modules
        setModules(_.orderBy(
          modules?.filter((module) => module.type === 'ai'),
          [(module) => module.order_number || Number.MAX_VALUE], 
          ['asc']
        ));
        setCoreModules(_.orderBy(
          modules?.filter((module) => module.type === 'core'),
          [(module) => module.order_number || Number.MAX_VALUE], 
          ['asc']
        ))
        setInterview(_.orderBy(
          modules?.filter((module) => module.type === 'interview'),
          [(module) => module.order_number || Number.MAX_VALUE], 
          ['asc']
        ))
      } else {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
        
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
      
    }
  };

  useEffect(() => {
    fetchModules(courseId);
  }, [courseId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "youtubeLink") {
      setNewModule((prevModule) => ({
        ...prevModule,
        [name]: value,
      }));
    } else {
      setNewModule((prevModule) => ({
        ...prevModule,
        [name]: value,
      }));
    }
  };

  const addNewModule = async () => {
    // const newModuleWithId = { ...newModule };
    const newModulePayload = {
      modules: [
        {
          module_name: newModule.module_name,
          module_description: newModule.module_description,
        },
      ],
    };
    try {
      const response = await AddNewmodule(courseId, newModulePayload);
      

      setShowAddModal(false);
      setNewModule({
        module_name: "",
        module_description: "",
        youtubeLink: "",
      });
      await fetchModules(courseId);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
      
    }
  };

  const Addmodule = () => {
    setShowAddModal(true);
    // const id = generateId();
    // setGenerated(id);
  };
  
  const totalModules = modules?.length;
  return (
    <>
  <ToastContainer/>
      

<TopNavTrainee  courseId={courseId} selectedCourse={selectedCourse}
         />

{/* 
      <div className="flex items-center justify-between gap-8 mx-12 mb-5 lg:flex-row md:flex-row max-sm:flex-col sm:flex-col"> */}
        {/* <div className="border border-[#37383A] bg-[#1A1C1E] text-[#BDBEBE] p-2.5 rounded text-center flex-1">
          <h3>Dashboard</h3>
          <p>Modules</p>
        </div>

        <div className="border border-[#37383A] bg-[#1A1C1E] text-[#BDBEBE] p-2.5 rounded text-center flex-1">
          <h3>Total Number of Modules</h3>
          <p>{totalModules}</p> */}
        {/* </div> */}

        {/* <div className="">
          <Button
            variant="contained"
            onClick={() => Addmodule()}
            sx={{
              background: "#282B2F",
              color: "#BDBEBE",
              fontFamily: "Poppins, sans-serif",
              "&:hover": {
                background: "#282B2F",
              },
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add Module
          </Button>
        </div> */}
      {/* </div> */}

      <Modal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        aria-labelledby="add-new-module-modal"
        aria-describedby="form-to-add-new-module"
      >
        <Box className="absolute m-auto inset-0 h-fit lg:w-1/4 md:w-2/4 sm:w-2/4 max-sm:w-4/5 bg-[#242728] text-[#BDBEBE] border-2 border-[#37383A] p-8 rounded-lg">
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontFamily: "Poppins, sans-serif" }}
          >
            Add New Module
          </Typography>

          <TextField
            label="Name"
            variant="outlined"
            name="module_name"
            value={newModule.module_name}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& input": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          <TextField
            label="Description"
            variant="outlined"
            name="module_description"
            multiline
            rows={4}
            value={newModule.module_description}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& textarea": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          {/* <TextField
            label="YouTube Link (Embed)"
            variant="outlined"
            name="youtubeLink"
            value={newModule.youtubeLink}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& input": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          /> */}
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddModal(false)}
              sx={{
                mr: 2,
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#282B2F",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={addNewModule}
              sx={{
                background: "#282B2F",
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#282B2F",
                },
              }}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>
      <div className="flex flex-row">
        <div className="m-5 bg-[#1A1C1E] rounded-lg w-[60%]">
          <div className="m-5 bg-[#1A1C1E] rounded-lg">
            <div className="header-title">
              <h4>Core Modules</h4>
            </div>
            <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
              {coreModules?.length === 0 ? (
                // Loader component or loading message goes here
                <TailSpin
                  color="#FFFFFF"
                  height={20}
                  width={20}
                  style={{ margin: "10px 10px" }}
                /> // Render courses if not loading
              ) : coreModules ? (
                coreModules?.map((module) => (

                  <div
                    key={module.id}
                    className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
                  >
                    <Link
                      to={`/training/courses/${courseId}/module/${module.id}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {module.youtubeLink && (
                        <div className="mb-6">
                          <iframe
                            title="YouTube Video"
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${module.youtubeLink}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      )}
                      <h3 className="flex align-middle justify-start my-2.5 mx-2 text-base font-normalas">
                        {module.module_name}
                        {/* <FaEdit style={{ marginLeft: "50", cursor: "pointer" }} />{" "} */}
                        {/* Edit icon */}
                      </h3>
                      <p className="flex align-middle justify-start my-2.5 mx-2 text-base font-normal">
                        {module.module_description}
                      </p>
                    </Link>
                    <label className="absolute top-1 right-2">
                      {/* <input type="checkbox" /> */}
                      {/* Edit icon */}
                    </label>
                  </div>
                ))
              ) : (
                // Render something else if there are no courses
                <div className="text-[#BDBEBE]">No Core Modules available</div>
              )}
            </div>
          </div>

          <div className="m-5 bg-[#1A1C1E] rounded-lg">
            <div className="header-title">
              <h4>AI Enablement</h4>
            </div>
            <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
              {modules?.length === 0 ? (
                // Loader component or loading message goes here
                <div className="text-[#BDBEBE]">No AI Enablement available</div>
              ) : modules ? (
                modules?.map((module) => (

                  <div
                    key={module.id}
                    className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
                  >
                    <Link
                      to={`/training/courses/${courseId}/module/${module.id}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {module.youtubeLink && (
                        <div className="mb-6">
                          <iframe
                            title="YouTube Video"
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${module.youtubeLink}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      )}
                      <h3 className="flex align-middle justify-start my-2.5 mx-2 text-base font-normalas">
                        {module.module_name}
                        {/* <FaEdit style={{ marginLeft: "50", cursor: "pointer" }} />{" "} */}
                        {/* Edit icon */}
                      </h3>
                      <p className="flex align-middle justify-start my-2.5 mx-2 text-base font-normal">
                        {module.module_description}
                      </p>
                    </Link>
                    <label className="absolute top-1 right-2">
                      {/* <input type="checkbox" /> */}
                      {/* Edit icon */}
                    </label>
                  </div>
                ))
              ) : (
                // Render something else if there are no courses
                <div className="text-[#BDBEBE]">No AI Enablement available</div>
              )}
            </div>
          </div>

          <div className="m-5 bg-[#1A1C1E] rounded-lg">
            <div className="header-title">
              <h4>Getting Ready for Job</h4>
            </div>
            <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
              {interview?.length === 0 ? (
                // Loader component or loading message goes here
                <div className="text-[#BDBEBE]"> No data available</div>
              ) : interview ? (
                interview?.map((module) => (

                  <div
                    key={module.id}
                    className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
                  >
                    <Link
                      to={`/training/courses/${courseId}/module/${module.id}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {module.youtubeLink && (
                        <div className="mb-6">
                          <iframe
                            title="YouTube Video"
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${module.youtubeLink}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      )}
                      <h3 className="flex align-middle justify-start my-2.5 mx-2 text-base font-normalas">
                        {module.module_name}
                        {/* <FaEdit style={{ marginLeft: "50", cursor: "pointer" }} />{" "} */}
                        {/* Edit icon */}
                      </h3>
                      <p className="flex align-middle justify-start my-2.5 mx-2 text-base font-normal">
                        {module.module_description}
                      </p>
                    </Link>
                    <label className="absolute top-1 right-2">
                      {/* <input type="checkbox" /> */}
                      {/* Edit icon */}
                    </label>
                  </div>
                ))
              ) : (
                // Render something else if there are no courses
                <div className="text-[#BDBEBE]"> No data available</div>
              )}
            </div>
          </div>
        </div>
        <div className="m-5 bg-[#1A1C1E] rounded-lg w-[30%]">
          <Bot id={null} type={'course'} prompts={courseDetails?.training_course_prompt} title={courseDetails?.modules?.length > 0 ? courseDetails?.modules.map(i => i.module_name) :[]} />
        </div>
      </div>

     
    </>
  );
};

export default Cources;
