/* eslint-disable react-hooks/rules-of-hooks */
import { Link, useNavigate } from "react-router-dom";
import "styles/Consultant.css";
import logo from "../../data/logo-light.svg";
import logo_gray from "../../data/logo-gray.svg";
import logo_only from "../../data/logo-ico.svg";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { AutoStories, ContactSupport, Logout, SmartToy } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { useLogoutFunction } from "@propelauth/react";
import PollinatorLogo from '../../data/pollinator-logo.png'

const sidebar = ({ sidebarOpen }) => {
  //   const [sidebarOpen, setSidebarOpen] = useState(false);
  // Change nav color when scrolling
  const navigate = useNavigate();
  const logout = useLogoutFunction();
  const handleLogout = (event) => {
    event.preventDefault();
    console.log("Logging out...");
    localStorage.removeItem("__PROPEL_AUTH_LOGGED_IN_AT");
    localStorage.removeItem("__PROPEL_AUTH_LOGGED_OUT_AT");
    localStorage.removeItem("auth");
    logout(false);
    // navigate("/");

    // window.location.href = "/";
    navigate("/");
  };

  return (
    <>
      <style>
        {`
      .sidebar::before {
        content: "";
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        left: -10%;
        background: url(${logo_gray}) no-repeat bottom / contain;
      }
      `}
      </style>
      <aside
        className={`w-20 flex flex-col bg-[#1A1C1E] text-white fixed inset-y-0 left-0 transition-all duration-300 ease-in-out z-30 sidebar ${sidebarOpen ? "-translate-x-full" : ""
          }`}
      >
        <nav className="flex-1 flex flex-col bg-[#1A1C1E] border-r border-[#37383A] pt-4">
          <Link
            to="/training"
            // onClick={handleCloseSidebar}
            className="flex items-center w-full gap-3 text-xl font-extrabold tracking-tight text-white uppercase dark:text-white"
          >
            <span className="w-full p-5 pt-0">
              <img src={logo_only} alt="logo"></img>
            </span>
            {/* <span><img src={logo} alt="logo"></img></span> */}
          </Link>
          <Tooltip title="Training" placement="right">
            <Link to="/training" className="nav-link">

              {<AutoStories />}
              {/* Training */}
            </Link>
          </Tooltip>
          <Tooltip title="Interview Prep" placement="right">
            <Link to="/training/interviewprep" className="nav-link">
              {<CoPresentIcon />}
              {/* Interview Prep */}
            </Link>
          </Tooltip>
          <Tooltip title="Pollinator" placement="right">
            <Link to="/training/pollinator" className="nav-link">
              {/* {<SmartToy />} */}
              <img src={PollinatorLogo} alt="pollinator" height={24} width={24}/>
              {/* Copilot */}
            </Link>
          </Tooltip>
          {/* <Link to="https://hcomb-bigagi-aca.victoriousbush-67842c2f.eastus.azurecontainerapps.io/" target="_blank" className="nav-link">
            Copilot
          </Link> */}
            <div className="flex-grow flex flex-col-reverse z-50 mb-16">
            <Tooltip title="Logout" placement="right">
            <Link onClick={handleLogout} className="nav-link">
              {<Logout />}
              {/* Hands on Support */}
            </Link>
          </Tooltip>
            <Tooltip title="Hands on Support" placement="right">
              
            <Link to="https://discord.gg/qstwvtbQEw" target="_blank" className="nav-link">
              {<ContactSupport />}
              {/* Hands on Support */}
            </Link>
          </Tooltip>
          
            </div>
          
        </nav>
      </aside>
    </>
  );
};

export default sidebar;
