/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getSpecificLessonDetail,
  getSpecificModuleLessons,
} from "../../services/api";
import Excercise from "./Excercise";
import Quizze from "./Quizze";
import Resource from "./Resource";
import BotChat from "services/BotChat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bot from "pages/Bot";
import TopNavTrainee from "./TopNavTrainee";
import AccessCourse from "components/AccessCourse";

const UserLessonDetail = () => {
  const [showVideoInfo, setShowVideoInfo] = useState(true);
  const [showQuizzes, setShowQuizzes] = useState(false);
  const [showExercises, setShowExercises] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const { moduleId, lessonId, courseId } = useParams();
  const [currentModule, setCurrentModule] = useState(moduleId);
  const [selectedLesson, setSelectedLesson] = useState("");
  const navigate = useNavigate();
  const { selectedCourse } = AccessCourse({ courseId });
  const [moduleName, setModuleName] = useState();
  const [lessonName, setLessonName] = useState();

  const videoUrl = selectedLesson?.overview?.url;

  const videoId = videoUrl?.split("/").pop().split("?")[0];

  useEffect(() => {
    setCurrentModule(moduleId);
  }, [moduleId]);

  useEffect(() => {
    const fetchModuleLessons = async () => {
      try {
        const moduleLessons = await getSpecificModuleLessons(moduleId);
        setModuleName(moduleLessons?.overview?.module_name);
        setCurrentModule(moduleId);
      } catch (error) {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
      }
    };

    fetchModuleLessons();
  }, [moduleId]);

  const fetchLessonData = async () => {
    // setLoading(true);
    try {
      const lessonData = await getSpecificLessonDetail(lessonId);

      setLessonName(lessonData?.overview?.title);
      setSelectedLesson(lessonData);
      // setLoading(false);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };
  useEffect(() => {
    fetchLessonData();
  }, [moduleId, lessonId]);

  if (!selectedLesson) {
    return <div>Lesson not found</div>;
  }

  const handlequizze = () => {
    setShowVideoInfo(false);
    setShowQuizzes(true);
    setShowExercises(false);
    setShowResources(false);
  };

  const handlexercises = () => {
    setShowVideoInfo(false);
    setShowQuizzes(false);
    setShowExercises(true);
    setShowResources(false);
  };

  const handlresources = () => {
    setShowVideoInfo(false);
    setShowQuizzes(false);
    setShowExercises(false);
    setShowResources(true);
  };


  const handlBackToVideo = () => {
    setShowVideoInfo(true);
    setShowQuizzes(false);
    setShowExercises(false);
    setShowResources(false);
  };
  return (
    <>
      <ToastContainer />
      <TopNavTrainee
        courseId={courseId}
        selectedCourse={selectedCourse}
        moduleName={moduleName}
        moduleId={moduleId}
        lessonId={lessonId}
        lessonName={lessonName}
      />

      <div className="flex flex-row">
        <div className="m-5 bg-[#1A1C1E] rounded-lg w-[60%]">
          <div className="header-title">
            <h4 className="text-[#BDBEBE] font-semibold text-xl my-4">
              Module: {moduleName}
            </h4>
          </div>

          <div className="flex flex-col items-center gap-3 m-5">
            {showVideoInfo && (
             
             <>
             {selectedLesson.overview.url && (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                }}
              >
                {selectedLesson.overview.url.includes("youtube.com") ||
                selectedLesson.overview.url.includes("youtu.be") ? (
                  <iframe
                    title="YouTube Video"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                    }}
                    src={`https://www.youtube.com/embed/${
                      selectedLesson.overview.url
                        .split("v=")[1]
                        ?.split("&")[0] ||
                      selectedLesson.overview.url.split("/").pop()
                    }`}
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <iframe
                    title="Video"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                    }}
                    src={selectedLesson.overview.url}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            )}
             </>
            )}

            <div className="w-full">
              <h2 className="text-2xl font-bold text-white">
                {selectedLesson.overview.title}
              </h2>
              <p className="font-normal text-white opacity-50 stext-lg">
                {selectedLesson.overview.lesson_description}
              </p>
            </div>
          </div>

          {/* Conditionally render components based on button clicks */}
          {/* {showQuizzes && (
            // Render the quizzes component here
            // You can pass necessary data as props to the Quizzes component
            <div className="">
              <Quizze quizzes={selectedLesson.quizzes} />
            </div>
          )} */}
          {/* {showExercises && (
            // Render the exercises component here
            // You can pass necessary data as props to the Exercises component
            <div className="">
              <Excercise exercises={selectedLesson.exercises} />
            </div>
          )} */}
          {showResources && (
            // Render the resources component here
            // You can pass necessary data as props to the Resources component
            <div className="">
              <Resource resources={selectedLesson.resources} />
            </div>
          )}

          <div className="grid grid-cols-3 gap-4 p-6 pt-0 md:grid-cols-3 max-sm:grid-cols-1 sm:grid-cols-1 lg:w-9/12 md:w-9/12 sm:w-9/12">
            {/* <div className="flex flex-wrap gap-3 m-5"> */}
            {/* <button
              onClick={handlequizze}
              disabled={showQuizzes}
              className={`${
                showQuizzes ? "bg-[#5b52e7] text-[#fff]" : "bg-[#282B2F] hover:bg-[#5b52e7] hover:text-[#fff] text-[#BDBEBE]"
              } rounded-[4px] p-3 border border-[#37383A] uppercase text-sm font-normal`}
            >
              Quizzes
            </button>
            <button
              onClick={handlexercises}
              disabled={showExercises}
              className={`${
                showExercises ? "bg-[#5b52e7] text-[#fff]" : "bg-[#282B2F] hover:bg-[#5b52e7] hover:text-[#fff] text-[#BDBEBE]"
              } rounded-[4px] p-3 border border-[#37383A] uppercase text-sm font-normal`}
            >
              Exercise
            </button> */}
            <button
              onClick={handlresources}
              disabled={showResources}
              className={`${
                showResources ? "bg-[#5b52e7] text-[#fff]" : "bg-[#282B2F] hover:bg-[#5b52e7] hover:text-[#fff] text-[#BDBEBE]"
              } rounded-[4px] p-3 border border-[#37383A] uppercase text-sm font-normal`}
            >
              Resources
            </button>
          </div>
          {!showVideoInfo && (

            <div>
            <p onClick={handlBackToVideo} className="text-[#BDBEBE] ml-5 hover:cursor-pointer">Back to video</p>
          </div>
          )}
          
        </div>
        <div className="m-5 bg-[#1A1C1E] rounded-lg w-[30%]">
          <Bot type={'lesson'} id ={lessonId} prompts={selectedLesson?.lesson_prompt} title={[selectedLesson?.overview?.title]}/>
        </div>
      </div>
    </>
  );
};

export default UserLessonDetail;
